export const stars = [1, 2, 3, 4, 5] as const;

export type Rating = (typeof stars)[number] | -1;

export const ratingMap = {
  5: 1,
  4: 2,
  3: 3,
  2: 4,
  1: 5,
  "-1": -1,
} as const;

export const enum PopupView {
  Rate = "rate",
  Happy = "happy",
  Sad = "sad",
}
