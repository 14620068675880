declare global {
  interface Window {
    __insp: any
  }
}

export const setInitialData = (app: { hostOrigin: string, localOrigin: string }) => {
  try {
    if (typeof window.__insp !== 'undefined') {
      const storeName = app.hostOrigin.replace('http://', '').replace('https://', '')
      const appName = app.localOrigin.replace('http://', '').replace('https://', '').replace('.polarbearapps.com', '')
      window.__insp.push(['identify', storeName])
      tagSession({ storeName, appName })
    }
  } catch (e) {
    console.error(e)
  }
}

export const tagSession = (tagData: object) => {
  try {
    window.__insp.push(['tagSession', tagData])
  } catch (e) {
    console.error(e)
  }
}
