import React, { ReactNode } from "react";
import style from "./RatePopup.module.scss";

interface FooterButtonProps {
  children: ReactNode;
  onClick: () => void;
}

export const FooterButton = ({ children, onClick }: FooterButtonProps) => (
  <button className={style.footerButton} onClick={onClick}>
    {children}
  </button>
);
